import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import PdfPreviewer from "views/Forms/PdfPreviewer.jsx";

import {
  BrowserRouter,
  Router,
  Route,
  Link,
  Redirect,
  withRouter,
  Switch
} from "react-router-dom";

const hist = createBrowserHistory();

window.utf8_to_b64 = function(str) {
    return window.btoa(unescape(encodeURIComponent( str )));
};

window.b64_to_utf8 = function(str){
    return decodeURIComponent(escape(window.atob( str )));
};

ReactDOM.render(
  <BrowserRouter basename={window.BASE_NAME}>
    <Switch>
      <Route path="/" component={PdfPreviewer} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
)

