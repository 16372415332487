import React from "react";
import PropTypes from "prop-types";
import PDF from 'react-pdf-js-infinite';

const style = theme => ({

});

class PdfPreviewerView extends React.Component {
    constructor(props) {
        super(props);

        this.WS_URL = window.WS_URL;

        let url = new URL(window.window.location.href);
        let data = url.searchParams.get("data");

        if (data) {
            this.state = {
                data: JSON.parse(window.b64_to_utf8(data))
            };
        }
    }

    componentDidMount() {
        document.getElementById("root").style.overflow = 'auto';
        document.getElementById("root").style.width = '100%';
        document.getElementById("root").style.height = '100%';
    }

    render() {
        let that = this;
        let userData = this.state.data.userData;

        if (this.state.data.type === 'normal'){
            let url = this.WS_URL + '/api/v1/file/' + userData.token +'/pdf_by_id/' + this.state.data.id;

            return (
                <PDF
                    file={url}
                    scale={1}
                />
            );
        } else if (this.state.data.type === 'signature_file'){
            let url = this.WS_URL + '/api/v1/re/' + userData.token +'/downloadFisierSemnaturaReLink/' + this.state.data.name;

            return (
                <PDF
                    file={url}
                    scale={1}
                />
            );
        }
    }
}

PdfPreviewerView.propTypes = {
    classes: PropTypes.object.isRequired
};

export default PdfPreviewerView;
